import React from 'react'
import { Navbar } from "../layout/navbar";
import "../style/contactUs.css"
import axios from 'axios';
import { useState } from "react";

export const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post('https://727instrumental.org/backend/public/api/contactUs', {name, email, phone, message});
        if (response.data.status) {
          console.log(response.data.status);
          window.location = "https://727instrumental.org";
        }
      } catch (error) {
        console.log('Error Processing The Info:', error);
      }
      console.log("User Input:", {name, email, phone, message});
    }

    return(<>
<div>
      <Navbar />

      <div>&nbsp;</div>
      <div className='left'>
        <h1>Contact Us</h1>
        <div>&nbsp;</div>
        <h2 className='nextText'>We are here to help period Reach out to us using the contact details below or simply fill out the form.</h2>
        <p className='filler'>
        <div>&nbsp;</div>
        Email: contact@727instrumental.org
        <div>&nbsp;</div>
          Thank you,<br />
          Mark DiGiorgio
        <div>&nbsp;</div>
        </p>
        
        <div>&nbsp;</div>
      </div>
      <div className='right'>

      <form onSubmit={handleSubmit}>

        <div className='makeone' style={{textAlign:'left', marginLeft:'50px', marginTop:'0px'}}>Fill Out These Details</div>
        <p className='filler' style={{textAlign:'left', marginLeft:'50px'}}>Once we have your information,<br></br>
        we'll respond to your inquiry promptly and efficiently.</p>
        <div style={{color: "#ffffff", fontSize: "18px", width: "70%", marginLeft: "50px"}}>
          <div className=''>
           <input className='ContactUsText' placeholder='Your Name *' type='text' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>

        <div style={{clear: "both", height: "10px"}}>&nbsp;</div>

            <div style={{color: "#ffffff", fontSize: "18px", width: "70%",  marginLeft: "50px", marginBottom:'0px'}}>
          <div className=''>
          <input className='ContactUsText' placeholder='Email Address *' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>

        <div style={{clear: "both", height: "10px"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%",  marginLeft: "50px"}}>
          <div className=''>
          <input className='ContactUsText' placeholder='Phone *' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} />
          </div>
        </div>

        <div style={{clear: "both", height: "10px"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%",  marginLeft: "50px"}}>
          <div className=''>
           <textarea style={{height:"100px"}} placeholder='Type Your Message *' className='ContactUsText' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
          </div>
        </div>

        <div style={{clear: "both"}}>&nbsp;</div>

        <div style={{color: "#ffffff", fontSize: "18px", width: "70%",  margin: "50px"}}>
          
           <input className='donateBtn' type='submit' value={"Submit My Details"} />
        
        </div>

        </form>

      </div>

    </div>
    </>
)}