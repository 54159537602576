import React from 'react';
import { Navbar } from "../layout/navbar";
import './../style/aboutUs.css'

export const AboutUs = () => {
  return (
    <div>
        <Navbar />
        <div style={{fontSize: "60px", color: "#ffffff"}}> About Us </div>
        <div>&nbsp;</div>
        <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
        Back in 3rd grade while attending Webster Elementary School, I wanted to learn and play the saxophone. There was this one famous solo artist back in the late 70's that was all over the TV. He appeared on almost every talk show at the time. So I wanted to follow in his footsteps.
        Soon as I was able to sign up for the school band I did with no hesitation. I was given an Alto Saxophone and was told by my instructor Mr. Mastroleo that after 1 month I will have to give it back and my parents will have to buy me one if I wanted to continue playing.
        So right before the month was up, I went to my father and told him that he would have to buy me a new sax so I could continue in the school band. The next day after work, he says to me that he went to try and buy one but the prices were extremely high and couldn't afford to buy me one.
        </div>
<div>&nbsp;</div>
            <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
            Sadly at the next band practice I went to tell Mr. Mastroleo that my parents weren't able to afford to buy me a saxophone and that I would have to back out of band class. He said to me he would go ask the city school district about seeing if they would allow me to continue using the school supplied sax. Sure enough, the next week he was able to get me to borrow the sax for the remainder of that school year and every year through to 8th grade.
            </div>

            <div>&nbsp;</div>

            <div style={{color: "#ffffff", width: "75%", textAlign: "center", fontSize:"20px", margin:"0 auto"}}>
            I started this new non-profit so that no young kid would have give up their dream of being able to play a musical instrument and not have the experience in playing in a school band. We are here to help and inspire our youth in our community
            </div>

            <div><img src='./mee.png' className='imgmee' /></div>

            <div className='post'>
            MARK DIGIORGIO</div>
            <div className='post'>
            President</div>


            <div>&nbsp;</div>
            <div style={{height: "5px", background: "#fff", margin: "0 auto", width: "90%"}}>&nbsp;</div>
            <div>&nbsp;</div>

            <div>


              <div className='leftabout'>

              <div><img src='./tre.jfif' className='imgmee2' /></div>

                <div className='post2'>
                Joanne Galli</div>
                <div className='post2'>
                Treasurer</div>

              </div>

              <div className='leftabout'>

              <div><img src='./MICHAEL.jfif' className='imgmee2' /></div>

                <div className='post2'>
                MICHAEL DIGIORGIO</div>
                <div className='post2'>
                SECRETARY</div>

              </div>
            

            
            </div>

            

            <div>&nbsp;</div>

    </div>
  )
}