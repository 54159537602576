import React from 'react';
import { Navbar } from '../layout/navbar';
import './../style/gearUp.css'

export const GearUp = () => {
  return (
    <div>
        <Navbar />

        <h1 style={{color: "#fff"}}>GearUp With Our Latest Merchandise</h1>

        <div className='mainShopContainer'>
            <div className='Blocks4'>
                <div>
                    <img src='' alt='' className='itemImage' />
                </div>
                <div className='itemDes'>
                    <p></p>
                </div>
                <div className='itemPrice'>
                    <p>Price: </p>
                </div>
                <div className='shopBtn'>
                    <input type='submit' value="Buy Now" />
                </div>
            </div>
        </div>

    </div>
  )
}